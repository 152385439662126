.badge-green {
    font-size: 12px;
    color: rgba(45,100,206);
    background-color: rgba(45,100,206, .2);
    padding: 5px;
}

.card-header-text {
    font-weight: 600;
}

.badge-red {
    font-size: 14px;
    color: #fa0505;
    background-color: rgba(250, 5, 5, .2);
    padding: 5px;
}

.ant-card-head {
    background: rgba(0,0,0, .2);
    color: #fff;
    border-bottom: 1px solid #020115 !important;
}

.ant-card-body {
    background: rgba(0,0,0, .2);
}

.tooltip {
    margin-top: -5px !important;
}

.customcard {
    transition: box-shadow .3s;
    background-color: rgba(100, 101, 118, .2);
}

/* .customcard:hover {
    transition: box-shadow .3s;
    box-shadow: 0 5px 15px rgba(100, 101, 118, .3);
} */

@media only screen and (min-width: 800px) {
    .chart {
        margin-top: -40px;
    }
    
}
@media only screen and (max-width: 1024px) {
    .contract-address {
        display: none !important;
    }
    .contract-address-mobile {
        font-size: 10px;
        margin-top: 5px;
        background-image: linear-gradient(60deg, #0e0a3c , #302c3f);
    }
    .desktop-chart {
        display: none;
    }
    .contract-address-section {
        font-size: 15px !important;
        padding-left: 18% !important;
        margin-bottom: 20px;
    }
    .liquidity-info-container {
        padding: 0px 25px;
    }
    .mobile-chart {
        padding: 0px 25px;
    }
    .mobile-top-margin {
        margin-top: 10px;
    }
    .contract-name {
        font-weight: 700;
        color: #bbbbbb;
        font-size: 15px !important;
    }
    .project-contracts-section {
        display: flex;
        align-items: center;
        /* justify-content: flex-center; */
        padding-left: 10% !important;
    }
    .user-divided-data-value {
        text-align: end !important;
    }
    
}
@media only screen and (max-width: 425px) {
    .contract-address-section {
        font-size: 10px !important;
        overflow:auto;
    }
}
@media only screen and (min-width: 1024px) {
    .contract-address-mobile {
        display: none !important;
    }
    .contract-details-card {
        margin-right: 20px;
    }
    .mobile-chart {
        display: none !important;
    }
    .chart-widget {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }
    .desktop-margin-top {
        margin-top: 30px;
    }
}
.sidebar-icon {
	margin-right: 10px;
}
.contract-address {
	margin-top: 1px;
	margin-left: 10px;
    background-image: linear-gradient(60deg, #0e0a3c , #302c3f);
    padding: 0px 20px;
}
.liquidity-values {
    font-size: 10px !important;
}
.ant-divider-horizontal {
    margin: 6px 0;
}

.contract-name {
    font-weight: 700;
    color: #bbbbbb;
    font-size: 18px;
}

.project-contracts-section {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
}

.contract-details-container {
    display: flex;
    
}

@media only screen and (min-width: 1024px) {
    .project-contracts-section {
       padding-left: 30px;
    }
}
.common-user-widget {
    padding: 20px;
}
.data-info-widget-title{
	font-size: 20px;
	margin-bottom: 10px;
    font-weight: 700;
}
.data-info-row {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    line-height: 35px;
}
.user-divided-data-title {
    font-size: 16px;
}
.user-divided-data-value {
    font-weight: 700;
    font-size: 18px;
}
.data-info-widget{
    height: 400px;
}
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    background: #252422;
    color: #fff;
}
