/* .login-form {
    max-width: 300px;
} */

.input-background-inside input {
    background: rgba(100, 101, 118, 0); 
    color: white;
}


.input-background-outside {
    background: rgba(100, 101, 118, .2); 
    color: grey;
    border: none;
}


.login-form-forgot {
    text-decoration: none;
    float: right;
    color: rgba(45,100,206);
    
}

.ant-col-rtl .login-form-forgot {
    float: left;
}

.login-form-button {
    background-color: rgba(20, 203, 108, .2);
    color: rgba(20, 203, 108);
    border: none;
}

.login-form-button:hover {
    background-color: rgba(20, 203, 108, .2);
    color: rgba(20, 203, 108);
    border: none;
    box-shadow: 0 5px 15px rgba(20, 203, 108, .2);
}

.register_link {
    text-decoration: none;
    color: rgba(45,100,206);
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
}

.icon-white {
    color: white;
}

.ant-slider-track {
    position: absolute;
    height: 4px;
    background-color: rgba(45,100,206);
    border-radius: 2px;
    transition: background-color .3s;
    color: 'white' !important;
}

.ant-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #fff;
    border: solid 2px rgba(45,100,206);
    border-radius: 50%;
    box-shadow: 0;
    cursor: pointer;
    transition: border-color .3s,box-shadow .6s,transform .3s cubic-bezier(.18,.89,.32,1.28);
    color: 'white' !important;
}

.ant-slider-dot-active {
    border-color: #8cc8ff
}

.ant-slider:hover .ant-slider-rail {
    background-color: #e1e1e1
}

.ant-slider:hover .ant-slider-track {
    background-color: rgba(45,100,206)
}

.cal-card > .ant-card-body {
    box-shadow: none !important;
    background: #141418;
}

.daily-volume-help-text {
    margin-top: 5px;
    font-style: italic;
    font-weight: 600;
    font-size: 11px;
    color: #ffad25;
}