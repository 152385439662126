.selected-box {
    border-radius: 16px;
	cursor: pointer;
    color: #1d1d1d;
}

.mint-box {
	cursor: pointer;
    color: #ffffff;
    background: #121212;
    border-radius: 16px;
    align-items: center !important;
}



.preview-container {
    padding: 0 10px;
  }
.nft-name-input {
    background-color: #3b3b3e;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #3b3b3e;
}
.nft-description {
    margin-top: 5px;
    font-size:13px;
    word-wrap: break-word;
  }
.nft-metadata-preview {
    margin-top: 10px;
    color: #ffffff;
}

.nft-minting-input-labels{
    text-align: start;
}

.nft-metadata-container {
    text-align: center;
    color: #feffff;
    margin-top: 10px;
}
.nft-input {
    color: #ffffff !important;
    background: #161528;
    border-radius: 5px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #161528;
}

.lkjDVC {
    border: dashed 2px #6C757D !important;
    color: #EFBC00 !important;
}
.input-group>.form-control, .input-group>.form-select {
    border-radius: 5px;
}
.ant-image-img {
    border-radius: 16px !important;
}
.nft-name {
    text-align: center !important;
}
.nft-mint-text {
    color: #6C757D;
}
.video-card {
    border-radius: 16px !important;
}

.input-background-inside input {
    background: rgba(100, 101, 118, 0); 
    color: white;
}

.input-background-outside {
    background: rgba(100, 101, 118, .2); 
    color: grey;
    border: none;
}
@media only screen and (max-width: 1024px) {
    .mobile-padding-nft-mint {
        margin-top: 10px;
    }
    .custom_upload_area {
        padding: 30px !important;
    }
}