.nft-search-bar  input {
  background: #161528;
  color: #fff;
}

.nft-container {
  min-height: 437px;
  padding: 10px;
  margin: 20px 0px;
  border: 1px solid #4e4e4e;
}

.user-wallet-address {
  font-size: 13px;
  padding: 5px;
  border-radius: 45px;
  background: #161528;
  z-index: 2;
}

.profile-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-profile-image {
  z-index: 2;
}

.nft-asset-container {
  min-height: 300px;
  margin: 0 10px;
  position: relative;
  margin-top: -19px;
}

.nft-name {
  text-align: start;
  margin-top: 5px;
  margin-left: 10px;
  color: #fff;
  font-weight: 500;
}

.nft-token-id {
  text-align: start;
  margin-top: 5px;
  margin-left: 10px;
  color: #fff;
  font-weight: 500;
}

.image-loader{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nft-image-container {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nft-details-owner-section {
  margin-top: 20px;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nft-details-wallet-address {
  border: 2px solid darkred;
  font-weight: 800;
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 20px;
}

.nft-details-title {
  color: #fff;
  margin-top: 20px;
  font-size: 20px;
  text-align: start;
}

.nft-details-description {
  color: #fff;
  margin-top: 10px;
  font-size: 13px;
  text-align: start;
}

.nft-details-token-id {
  color: #fff;
  margin-top: 10px;
  font-weight: 800;
  font-size: 13px;
  text-align: start;
}

.nft-details-profile {
  margin-right: 10px;
}

.nft-details-owner-label {
  text-align: start;
  color: #fff;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
.ant-select-selection-overflow {
  background-color: #252422 !important;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #252422;
}
.input-group > .form-control {
  color: white;
}
.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
  background: #252422 !important;
}
