
.network-selector-section {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.total-token-balance {
  display: flex;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  justify-content: flex-start;
}

.bnb-balance-details {
  text-align: start;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
}

.native-fluctuation-container {
  font-size: 15px;
  color: #fff;
  display: flex;
}

.fluctuation-red {
  color: #e10303;
}

.fluctuation-green {
  color: #00ec42;
}

.fl-container{
  margin-right: 10px;
}

span.fl-text {
  font-size: 10px;
  color: #ffffff;
}

.warning-text {
  color: #fff;
  text-align: start;
  font-style: italic;
  font-size: 14px;
  margin: 10px 0px;
}

.ant-table-thead > tr >th{
  color: white !important;
  font-size: 12px;
  background: #121212 !important;
}

.ant-table-tbody > tr >th{
  color: white;
  background: #1e1e1e !important;
  }

tr.ant-table-row.ant-table-row-level-0 {
    background: #1e1e1e;
}

.ant-table-cell .ant-table-cell-row-hover{
  background: #efbb0032 !important;
}

.ant-table-tbody>tr.ant-table-row:hover>td{
  background: #efbb0032 !important;
}

.custom_red{
  color: red;
}

.custom_green {
  color: #00EC42;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #030202 !important;
  transition: background 0.3s;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 5px;
}

td.ant-table-cell {
  color: #fff;
}

td.ant-table-cell.ant-table-column-sort {
  background: #1e1e1e;
}
.token-list-table {
  overflow: scroll !important;
}
@media only screen and (max-width: 500px) {
  .hidden-on-mobile {
    display: none !important;
  }
  .token-name-container {
    font-size: 10px !important;
  }
  .table-mobile-font {
    font-size: 10px !important;
  }
}

.tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background: #1e1e1e;
}