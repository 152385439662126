.pool-detail-card {
    background-color: #f7ba09;
}

.accordion-item {
    background-color: #121120;
    border-radius: 20px !important;
}

.accordion-item:first-of-type .accordion-button {
    background-color: #0d0e16;
    color: #ffff;
    font-weight: 700;
    border-radius: 20px !important;
}

.accordion-button:not(.collapsed) {
    background-color: #303032;
    border-radius: 20px !important;
}

.text-light {
    font-size: 20px;
}

.ant-card-body {
    background: rgba(0, 0, 0, .2);
}

.total-busd-card {
    font-size: 15px !important;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer;
    background-color: #100d31;
    border: solid 1px #f7ba09;
    color: #f7ba09;
}

.pool-value {
    color: white;
    text-align: center;
}

.pool-header {
    text-align: center;
    color: #121120;
}

.card-header {
    color: white;
    font-size: 15px;
    font-weight: 700;
}

.staking-pool-card {
    background-color: #f7ba09;
    border: solid 1px #110d31 !important;
    /* padding-bottom: 30px; */
}

.ant-card-body {
    background: rgba(0, 0, 0, .2);
    /* background-color: black; */
}

.early-withdraw-button {
    border: solid 1px red !important;
    color: red !important;
}

.approve-busd-button {
    border: solid 1px white !important;
    color: white !important;
}

.form-control {
    background-color: #252422 !important;
}

.wallet-label {
    color: white;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    color: white;
}

.token-detail-box {
    font-size: 14px;
    color: #f3f1f1;
}

.staking-widget-values {
    color: #f3f1f1 !important;
    font-size: 20px;
    font-weight: 700;
    text-align: start;
}

.staking-guide-container {
    text-align: start;
}

span.staking-guide {
    color: #6C757D;
}

.custom-per-button-margin {
    margin-right: 5px;
}

.remaining-pool-value-container {
    text-align: center;
    font-size: 11px;
}

.pool-value {
    font-size: 13px;
    font-weight: 700;
    text-align: center;
}

.stake-details-row {
    font-weight: 700;
    color: white;
}

.stake-details-label {
    font-size: 14px;
    font-weight: 400;
    color: #6C757D;
    list-style-type: square;
}

ul.stake-details-label {
    list-style-type: square;
    font-size: 14px;
    font-weight: 400;
    color: #6C757D;
}

.stake-details-value {
    font-size: 14px;
    font-weight: 400;
    color: #ffff;
    margin-left: 10px;
}

.token-stake-amount-info {
    font-size: 13px;
    font-weight: 600;
    font-style: italic;
    color: #ffffff !important;
}

.accordion-button:not(.collapsed) {
    font-weight: 700;
    background-color: #0d0e16;
    border-color: #F0b90b;
    color: #ffffff;
    border-radius: 20px;
}

.accordion-button.collapsed {
    font-weight: 700;
    background-color: #0d0e16;
    border-color: #F0b90b;
    color: #ffffff;
    border-radius: 20px !important;
}

.accordion-body {
    background: #212529;
    border-radius: 20px !important;
}

.staking-tab-container {
    margin-bottom: 20px;
}

.staking-guide-container {
    color: #f7ba09;
}

.stake-details-container {
    font-size: 14px;
}

.apy-widget-container {
    display: flex;
    border: 1px solid #ffffff;
    /* background: #efbc00; */
    background-image: repeating-linear-gradient(to right, #000000 0%, #1b1a18 20%, #2b251b 30%, #362c18 40%, #5c4f2b 50%, #362c18 60%, #2b251b 70%, #1b1a18 80%, #000000 100%);
    height: min-content;
    min-height: 200px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    color: white;
}

.apy-widget-header .header-title {
    font-weight: 700;
    font-size: 10px;
}

.apy-remaining-pool-value {
    font-weight: 800;
    font-size: 15px;
}

.holding-period {
    font-size: 50px;
    font-weight: 900;
}

.apy-widget-middle-section {
    /* padding: 5px 0px; */
}

.footer-apy-percentage {
    font-weight: 900;
    /* padding: 5px 0px; */
    background: #000;
    color: #ffffffff;
}

.apy-widget-header {
    padding: 5px;
    align-items: center;
}

.staking-amount-input {
    color: #ffffffff;
}

.staking-amount-input:focus {
    color: #ffffffff;
}

.staking-definition-container {
    margin-bottom: 20px;
    padding: 10px;
}

.definition-item {
    font-size: 15px;
    margin-bottom: 5px;
    text-align: start;
}

span.glossary {
    font-weight: 700 !important;
    font-size: 15px !important;
}

.staking-definition-title {
    font-weight: 400;
}

.staking-step-guild-header {
    text-decoration: underline;
}

.staking-step-item-container {
    text-align: start;
    font-size: 12px;
    font-weight: 700;
}

.staking-guild-item {
    margin-bottom: 5px;
}

.accordion-item:first-of-type .accordion-button {
    height: 30px;
}

.accordion-button:not(.collapsed) {
    height: 10px !important;
}

.accordion-button.collapsed {
    height: 10px !important;
}

@media only screen and (min-width: 1024px) {
    .investor-details {
        font-size: 14px !important;
        height: 190px !important;
        text-align: center;
    }

    .stake-details-row {
        display: flex;
    }
}

@media only screen and (max-width: 1024px) {}

.staking-detail-card {
    margin-bottom: 15px;
    transition: box-shadow .3s;
    background-image: repeating-linear-gradient(to right, #000000 0%, #1b1a18 20%, #2b251b 30%, #362c18 40%, #5c4f2b 50%, #362c18 60%, #2b251b 70%, #1b1a18 80%, #000000 100%);
}

.accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: 1rem;
}

.ever-earn-card-bg {
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0.05) 100%) !important; */
    border-radius: 16px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    border: 1px solid rgba(230, 190, 79, 0.4) !important;
}

.remaining-card {
    align-items: center;
}

.holding-period-unit {
    align-items: center !important;
}

.staking-definition-cards {
    /* height: 250px; */
    border: 1px #6C757D dashed;
    background: #1E1E1E;
}

.staking-days-card-header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 4px 20px;
    color: #121212 !important;
    font-size: 16px;
    font-weight: 900;
    /* box-shadow: 0px 10px 8px 10px #e90707; */
}

.staking-definition-text-muted {
    color: #6C757D;
    font-size: 14px;
    font-weight: 400;
}

.staking-remaining-value {
    margin-top: 20px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
}

.staking-button {
    border: none !important;
    color: #1E1E1E !important;
    font-weight: 700 !important;
}

.staking-payout-header {
    font-size: 18px !important;
    font-weight: 700;
    margin-top: -5px;
    margin-left: 10px;
}

.staking-page-logo {
    height: 17px;
    /* margin-right: 10px; */
}

.staking-pool-header {
    font-size: 14px !important;
    font-weight: 700;
    margin-top: -2px;
    margin-left: 10px;
}

.staking-pool-value {
    font-size: 14px !important;
    font-weight: 700;
    margin-top: -2px !important;
    margin-left: 10px;
    color: #ffff;
}

.staking-remaining-busd {
    font-size: 14px !important;
    font-weight: 400;
    color: #ffff;
}

.staking-process-header {
    font-size: 18px !important;
    font-weight: 700;
}