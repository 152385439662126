.todo-card {
    background: #edbb0e !important;
    /* padding: 10px !important; */
}
.todo-inner {
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}
a {
    text-decoration: none;
  }
.todo-header {
    color: white;
}
.ifram-card {
    width: 500px !important;
}
.iframe-styles {
    border: red 2px solid !important;
}
@media only screen and (max-width: 1024px) {
    .ifram-card {
        display: none !important;
    }
    
}
@media only screen and (min-width: 1024px) {
    .ifram-card-mobile {
        display: none !important;
    }
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-weight: 800;
}

.ant-collapse {
    margin-top: 10px !important;
    border-radius: 10px !important;
    border: #edbb0e 1px solid !important;
}
.ant-collapse-content {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
.ant-collapse-item {
    border-radius: 10px !important;
    border: none !important;
}
